import * as React from "react"
import Seo from "../../../components/seo"
import ReduxWrapper from "../../../redux/reduxWrapper"
import AdminPage from "../../../components/SuperAdmin/AdminPage/adminPage"
import AdminPrivateRoute from "../adminPrivateRoute"

const IndexPage = props => {
  return (
    <React.Fragment>
      <Seo title="All Users" />
      <AdminPrivateRoute component={AdminPage} location={props.location} />
    </React.Fragment>
  )
}

const WrappedPage = props => <ReduxWrapper element={<IndexPage {...props} />} />
export default WrappedPage
